import {handleLogout} from "components/utils"; import React, {useState} from "react";
import Link from "next/link";
import {Button, InputGroup, Modal, Form} from "react-bootstrap";
import axios from "axios";
import {validateEmail} from "../utils";
import * as ga from "../../lib/gtag";
import Image from 'next/image'

export default function FooterView() {


    const [email, setEmail] = useState(null)
    const [showAlert, setShowAlert] = useState(false);

    const handleCloseAlertModal = () => setShowAlert(false);
    const handleShowAlertModal = () => setShowAlert(true);

    function getModalForAlert() {
        return <Modal
            show={showAlert}
            onHide={handleCloseAlertModal}
            backdrop="static"
        >
            <Modal.Body className="text-center my-2 fs-4">
                Subscription Successful!
            </Modal.Body>
        </Modal>;
    }

    const [showIncorrectAlert, setShowIncorrectAlert] = useState(false);

    const handleCloseIncorrectAlertModal = () => setShowIncorrectAlert(false);
    const handleShowIncorrectAlertModal = () => setShowIncorrectAlert(true);

    function getModalForIncorrectAlert() {
        return <Modal
            show={showIncorrectAlert}
            onHide={handleCloseIncorrectAlertModal}
            backdrop="static"
        >
            <Modal.Body className="text-center my-2 fs-4 text-danger">
                Incorrect Email Id!
            </Modal.Body>
        </Modal>;
    }

    const handleSubmit = () => {
        if (!validateEmail(email)) {
            handleShowIncorrectAlertModal()
            setTimeout(() => {
                handleCloseIncorrectAlertModal()
            }, 2000);
        } else {
            axios.post('/api/backend/leads/create/', {
                email: email,
                interest: "NEWSLETTER"
            })
                .then(resp => {
                    handleShowAlertModal()
                    setTimeout(() => {
                        handleCloseAlertModal()
                    }, 2000);
                })
                .catch(err => {
                    console.error(err); if (err.response && err.response!.status && err.response!.status === 403)  { handleLogout() };
                });
        }
    }

    return (
        <div className="row bg-white hero landing footer-text footer-style">
            {getModalForAlert()}
            {getModalForIncorrectAlert()}
            <div className="col col-12 col-lg-10 offset-lg-1 my-3">
                <div className="d-none d-md-block">
                    <div className="row text-secondary footer-text pb-4">
                        <div className="col col-4">
                            <div className="my-3"><img
                                src="/footer-logo.svg" alt="qme-footer-logo"/></div>

                            <div className="my-3 footer-small-text">
                                Empower Your Services with QME&apos;s Hospital Management Solution in order to achieve
                                maximum automation and efficiency and enhance the patient&apos;s experience.
                            </div>
                        </div>
                        <div className="col col-2 fs-7">
                            <div className="text-primary footer-heading my-3">Pages</div>
                            <div className="my-2"><Link href="/">Home</Link></div>
                            <div className="my-2"><Link href="/login">Login</Link></div>
                            <div className="my-2"><Link href="/privacy">Privacy Policy</Link></div>
                            <div className="my-2"><Link href="/blogs">Blogs</Link></div>
                        </div>
                        <div className="col col-2">
                            <div className="text-primary footer-heading my-3">Contact</div>
                            <div className="my-3">contact@qme.co.in</div>
                            <div className="my-3">+91-9971513514</div>
                            <div className="my-3">Crossings Republik, Ghaziabad</div>
                        </div>
                        <div className="col col-4">
                            <div className="text-primary footer-heading my-3">NEWSLETTER</div>
                            Subscribe our newsletter to get more information about QMe&apos;s latest capability
                            additions.
                            <div className="input-group mt-3">
                                <input type="email" className="form-control" placeholder="Enter your email"
                                       onChange={e => setEmail(e.target.value)}
                                       aria-describedby="button-addon2"/>
                                <button className="btn btn-info text-white" type="button" id="button-addon2"
                                        onClick={handleSubmit}>→
                                </button>
                            </div>
                        </div>
                        <div className="col col-4 pb-3">
                            <a href="/qme.apk" className="d-inline-block align-self-center" onClick={() => {
                                ga.event({
                                    action: "app-download",
                                    params: {}
                                });
                            }
                            }><Image width="200rem" height="60rem"
                                   src="/android.png" alt="android-bot-logo"/></a>
                            <div className="d-flex gap-4 my-5">
                                <a href="https://www.facebook.com/qmehmis" rel="noreferrer" target="_blank" className="d-inline-block align-self-center"><Image
                                    width="30rem" height="30rem"
                                    src="/facebook.png" alt="facebook-logo"/></a>
                                <a href="https://www.instagram.com/qmehmis" rel="noreferrer" target="_blank"
                                   className="d-inline-block align-self-center"><Image width="30rem" height="30rem"
                                                                                     src="/instagram.png" alt="instagram-logo"/></a>
                                <a href="https://www.linkedin.com/company/hashpay-qme/" rel="noreferrer" target="_blank"
                                   className="d-inline-block align-self-center"><Image width="30rem" height="30rem"
                                                                                     src="/linkedin.png"  alt="linkedin-logo"/></a>
                                <a href="https://www.youtube.com/@qmehmis" rel="noreferrer" target="_blank" className="d-inline-block align-self-center"><Image
                                    width="40rem" height="30rem"
                                    src="/youtube.png" alt="youtube-logo"/></a>
                            </div>
                        </div>
                        <div className="col col-2 fs-7 overflow-scroll">
                            <div className="text-primary footer-heading my-3">Doctor</div>
                            <div className="my-2"><Link href="/info/appointment-management">Appointment
                                Management</Link></div>
                            <div className="my-2"><Link href="/info/e-prescription">E-prescription</Link></div>
                            <div className="my-2"><Link href="/info/e-prescription-writing">Prescription Writing</Link>
                            </div>
                            <div className="my-2"><Link href="/info/emr">EMR</Link></div>
                            <div className="my-2"><Link href="/info/electronic-health-records">EHR</Link></div>
                        </div>
                        <div className="col col-2 fs-7 overflow-scroll">
                            <div className="text-primary footer-heading my-3">Hospital</div>
                            <div className="my-2"><Link href="/info/hospital-info-systems">Hospital Information Systems</Link></div>
                            <div className="my-2"><Link href="/info/hospital-management-system">Hospital Management System</Link></div>
                            <div className="my-2"><Link href="/info/opd-management-software">OPD Management</Link></div>
                            <div className="my-2"><Link href="/info/queue-management">Queue Management</Link></div>
                            <div className="my-2"><Link href="/info/automated-billing">Automated Billing</Link></div>
                        </div>
                    </div>
                </div>
                <div className="d-md-none text-secondary">
                    <div className="row">
                        <div className="col col-12 text-center">
                            <div className="">
                                <img
                                    src="/footer-logo.svg" alt="qme-footer-logo"/>
                            </div>
                            <div className="my-4">
                                Empower Your Services with QME&apos;s Hospital Management Solution in order to achieve
                                maximum automation and efficiency and enhance the patient&apos;s experience.
                            </div>
                            <div className="text-primary my-4 footer-heading">NEWSLETTER</div>
                            <InputGroup className=" my-4">
                                <Form.Control type="email" placeholder="Enter your email"
                                              onChange={e => setEmail(e.target.value)}/>
                                <Button className="btn btn-info text-white" type="button" id="button-addon2"
                                        onClick={handleSubmit}>→</Button>
                            </InputGroup>

                            <div className="text-primary footer-heading mt-4 mb-3">Contact</div>
                            <div className="my-2">contact@qme.co.in</div>
                            <div className="my-2">91-9971513514</div>
                            <div className="mt-2 mb-4">Crossings Republik, Ghaziabad</div>
                        </div>
                        <div className="row pb-3 text-center">
                            <a href="/qme.apk" className="d-inline-block align-self-center" onClick={() => {
                                ga.event({
                                    action: "app-download",
                                    params: {}
                                });
                            }
                            }><Image width="200rem" height="60rem"
                                   src="/android.png" alt="android-bot-logo"/></a>
                            <div className="d-flex gap-4 my-4 justify-content-center">
                                <a href="https://www.facebook.com/qmehmis" rel="noreferrer" target="_blank" className="d-inline-block align-self-center"><Image
                                    width="30rem" height="30rem"
                                    src="/facebook.png" alt="facebook-logo"/></a>
                                <a href="https://www.instagram.com/qmehmis" rel="noreferrer" target="_blank"
                                   className="d-inline-block align-self-center"><Image width="30rem" height="30rem"
                                                                                     src="/instagram.png" alt="instagram-logo"/></a>
                                <a href="https://www.linkedin.com/company/hashpay-qme/" rel="noreferrer" target="_blank"
                                   className="d-inline-block align-self-center"><Image width="30rem" height="30rem"
                                                                                     src="/linkedin.png" alt="linked-in-logo"/></a>
                                <a href="https://www.youtube.com/@qmehmis" rel="noreferrer" target="_blank" className="d-inline-block align-self-center"><Image
                                    width="40rem" height="30rem"
                                    src="/youtube.png" alt="youtube-logo"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-secondary footer-text border-top">
                    <div className="col col-6 my-3">
                        Copyright © 2025 UI8 LLC.
                    </div>
                    <div className="col col-6 text-right my-3">
                        All rights reserved
                    </div>
                </div>
            </div>
        </div>
    )
};

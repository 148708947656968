export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
    (window as any).gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

// log specific events happening.
export const event = ({ action, params }) => {
    (window as any).gtag('event', action, params)
}